import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { BlogListing } from "../../components/blogListing"

export default function BlogPage() {
  return (
    <Layout>
      <SEO title="Blog | Primespot Services" />
      <BlogSection />
    </Layout>
  )
}

function BlogSection() {
  return (
    <div className="bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="relative max-w-lg mx-auto lg:max-w-7xl">
        <div>
          <h2 className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10 ">
            Blog
          </h2>
          <div className="mt-3 sm:mt-4 lg:grid lg:grid-cols-2 lg:gap-5 lg:items-center">
            <p className="text-xl leading-7 text-gray-500">
              Take a look at our blog section. Enjoy blog articles about
              software engineering, marketing, sales, and business
              administration.
            </p>
          </div>
        </div>
        <div>
          <div className="mt-6 border-t-2 border-gray-100 pt-10">
            <p className="text-sm leading-5 text-gray-500">May 22, 2020</p>
            <Link to="/blog/full-vps-server-set-up-guide" className="block">
              <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
                Full Server Set Up Guide Using Ubuntu 18.04 LTS With SSH,
                Docker, Nginx, Firewall, Fail2ban, etc.
              </h3>
              <p className="mt-3 text-base leading-6 text-gray-500">
                When it comes time to deploy an application, website, or a
                cluster of websites, DevOps begins to rear its ugly head. Let's
                face it, for many developers, DevOps is a nightmare. And not all
                of us have an IT and DevOps team to help us out of this
                nightmare.
              </p>
              <p className="mt-3 text-base leading-6 text-gray-500">
                This guide is a step-by-step guide designed to get you from a
                bare VPS on a service such as DigitalOcean to a
                fully-functioning and production-ready web server as quickly as
                possible.
              </p>
            </Link>
            <div className="mt-3">
              <Link
                to="/blog/full-vps-server-set-up-guide"
                className="text-base leading-6 font-semibold text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150"
              >
                Read full guide
              </Link>
            </div>
          </div>
        </div>
        <div className="mt-6 grid gap-16 border-t-2 border-gray-100 pt-10 lg:grid-cols-2 lg:col-gap-5 lg:row-gap-12">
          {/* Start blog posts */}
          <BlogListing
            href="/blog/cloud-66-first-impressions"
            title="Cloud 66 First Impressions"
            excerpt="Cloud 66 is a platform for quickly and easily deploying and maintinging Rails and Node software.  This article covers my first impressions while using this service for the first time."
            published="June 15, 2020"
          />
          <div>
            <p className="text-sm leading-5 text-gray-500">June 6, 2020</p>
            <Link
              to="/blog/rails-strong-parameters-shortcut-for-scoping-current-user"
              className="block"
            >
              <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
                Rails Strong Parameters Shortcut for Scoping current_user to
                Record
              </h3>
              <p className="mt-3 text-base leading-6 text-gray-500">
                This post covers a tip I learned for elegantly scoping the
                current user to the strong parameters in a rails controller.
              </p>
            </Link>
            <div className="mt-3">
              <Link
                to="/blog/rails-strong-parameters-shortcut-for-scoping-current-user"
                className="text-base leading-6 font-semibold text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150"
              >
                Read full story
              </Link>
            </div>
          </div>
          <div>
            <p className="text-sm leading-5 text-gray-500">June 4, 2020</p>
            <Link
              to="/blog/rendering-partial-outside-view-or-controller-rails-6"
              className="block"
            >
              <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
                Rendering a Partial Outside of a View or Controller in Rails 6
              </h3>
              <p className="mt-3 text-base leading-6 text-gray-500">
                Normally, rendering a partial in Rails is straightforward.
                Sometimes, however, you need to render a partial in unusual
                ways. I recently had this problem when trying to render a
                partial (as a string) for a library called <em>cable_ready</em>.
                I was unable to render the partial in the normal way from within
                a Stimulus Reflex file. Here is how I solved this problem.
              </p>
            </Link>
            <div className="mt-3">
              <Link
                to="/blog/rendering-partial-outside-view-or-controller-rails-6"
                className="text-base leading-6 font-semibold text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150"
              >
                Read full story
              </Link>
            </div>
          </div>
          <div>
            <p className="text-sm leading-5 text-gray-500">May 26, 2020</p>
            <Link
              to="/blog/rails-starter-config-database-postgresql"
              className="block"
            >
              <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
                Rails Starter config/database.rb with PostgreSQL
              </h3>
              <p className="mt-3 text-base leading-6 text-gray-500">
                If you're like me, you likely forget the exact format of the
                Rails config/database.rb file. This always has me hunting
                through the docs or old projects whenever I need to configure my
                database after starting a new project. This post will give you a
                very basic starter to help you get up and running a bit quicker.
              </p>
            </Link>
            <div className="mt-3">
              <Link
                to="/blog/rails-starter-config-database-postgresql"
                className="text-base leading-6 font-semibold text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150"
              >
                Read full story
              </Link>
            </div>
          </div>
          <div>
            <p className="text-sm leading-5 text-gray-500">May 20, 2020</p>
            <Link to="/blog/jsonb-postgresql-rails" className="block">
              <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
                JSONB in PostgreSQL with Rails
              </h3>
              <p className="mt-3 text-base leading-6 text-gray-500">
                JSONB is a PostgreSQL data type allowing you to store data as a
                real JSON object. It is stored in binary format and can be
                queried using special PostgreSQL JSONB query operators.
              </p>
            </Link>
            <div className="mt-3">
              <Link
                to="/blog/jsonb-postgresql-rails"
                className="text-base leading-6 font-semibold text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150"
              >
                Read full story
              </Link>
            </div>
          </div>
          <div>
            <p className="text-sm leading-5 text-gray-500">May 20, 2020</p>
            <Link to="/blog/website-images-seo-cro" className="block">
              <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
                Website Images for SEO and CRO
              </h3>
              <p className="mt-3 text-base leading-6 text-gray-500">
                Search Engine Optimization (SEO) and Conversion Rate
                Optimization (CRO) require special tactics for optimizing
                images.
              </p>
            </Link>
            <div className="mt-3">
              <Link
                to="/blog/website-images-seo-cro"
                className="text-base leading-6 font-semibold text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150"
              >
                Read full story
              </Link>
            </div>
          </div>
          <div>
            <p className="text-sm leading-5 text-gray-500">May 20, 2020</p>
            <Link
              to="/blog/simple-case-for-search-engine-ads"
              className="block"
            >
              <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
                Dear Manager Obsessed with SEO, the Simplest Case for Search
                Engine Ads
              </h3>
              <p className="mt-3 text-base leading-6 text-gray-500">
                SEO is great. But too many business people focus too much on
                SEO. Search engine ads should absolutely not be ignored as a
                great advertising option.
              </p>
            </Link>
            <div className="mt-3">
              <Link
                to="simple-case-for-search-engine-ads"
                className="text-base leading-6 font-semibold text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150"
              >
                Read full story
              </Link>
            </div>
          </div>
          <div>
            <p className="text-sm leading-5 text-gray-500">May 20, 2020</p>
            <Link to="/blog/what-is-copywriting" className="block">
              <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
                What is Copywriting?
              </h3>
              <p className="mt-3 text-base leading-6 text-gray-500">
                Copywriting isn't just any form of writing. It's a special
                writing technique designed to help you sell more product.
              </p>
            </Link>
            <div className="mt-3">
              <Link
                to="/blog/what-is-copywriting"
                className="text-base leading-6 font-semibold text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150"
              >
                Read full story
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
