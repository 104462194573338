import React from "react"
import { Link } from "gatsby"

interface Props {
  href: string
  title: string
  excerpt: string
  published: string;
}

export function BlogListing(props: Props) {
  return (
    <div>
      <p className="text-sm leading-5 text-gray-500">{props?.published}</p>
      <Link
        to={props?.href}
        className="block"
      >
        <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
          {props?.title}
        </h3>
        <p className="mt-3 text-base leading-6 text-gray-500">
          {props?.excerpt}
        </p>
      </Link>
      <div className="mt-3">
        <Link
          to={props?.href}
          className="text-base leading-6 font-semibold text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150"
        >
          Read full story
        </Link>
      </div>
    </div>
  )
}
